import { CCApiService } from './api-service';
import { PageResult, DeploymentLastUsed, LucidworksAIDeploymentsFilter, LucidworksAIModelsFilter } from '../../models';
import {
  LucidworksAIDeploymentResponseBody,
  LucidworksAIModel,
  LucidworksAIModelResponseBody,
} from '../../components/lucidworks-ai/interfaces';
import { UIConfig } from './config-service';
import _ from 'lodash';

export class LucidworksAIService {
  static ApiService: CCApiService;
  static lucidworksAIApi: string;
  static apiBasePath: string;
  static customerId: string;
  static analyticsApi: string;
  static validationApi: string;

  static init(config: UIConfig) {
    this.ApiService = new CCApiService(config.authClientId, config.authIssuerUri);
    this.apiBasePath = _.replace(config.apiBasePath, '/api', '');
    this.lucidworksAIApi = `${this.apiBasePath}/ai`;
    this.analyticsApi = `${_.replace(this.apiBasePath, 'internal', 'api')}/analytics`;
  }

  static async getModel(customerId: string, modelId: string): Promise<LucidworksAIModel> {
    const url = `${this.lucidworksAIApi}/customers/${customerId}/models/${modelId}`;
    return this.ApiService.performFetch(url);
  }

  static async getModels(filter?: LucidworksAIModelsFilter): Promise<PageResult<LucidworksAIModelResponseBody>> {
    const params = new URLSearchParams(filter);
    const url = params.toString() ? `${this.lucidworksAIApi}/models?${params}` : `${this.lucidworksAIApi}/models`;
    return this.ApiService.performFetch(url);
  }

  static async getDeployments(
    filter?: LucidworksAIDeploymentsFilter
  ): Promise<PageResult<LucidworksAIDeploymentResponseBody>> {
    const params = new URLSearchParams(filter);
    const url = params.toString()
      ? `${this.lucidworksAIApi}/deployments?${params}`
      : `${this.lucidworksAIApi}/deployments`;
    return this.ApiService.performFetch(url);
  }

  static async getDeploymentsLastUsed(customerId: string, deploymentId: string): Promise<DeploymentLastUsed> {
    return this.ApiService.performFetch(
      `${this.analyticsApi}/${customerId}/ml/predictions/latest?deployment_id=${deploymentId}`
    ).then(result => result);
  }

  static async deleteDeployment(customerId: string, deploymentId: string): Promise<void> {
    const url = `${this.lucidworksAIApi}/customers/${customerId}/deployments/${deploymentId}`;
    const fetchOptions = {
      method: 'DELETE',
    };
    return this.ApiService.performFetch(url, fetchOptions);
  }
}
